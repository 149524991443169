@font-face {
  font-family: 'Lato Hairline';
  src: url('/assets/fonts/Lato-Hairline.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('/assets/fonts/Lato-HairlineItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Lato';
  src: url('/assets/fonts/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}


$headings-font: 'Lato';
$body-copy-font: 'Lato';
